@import "./variable.scss";
@import "./media.scss";

.accordian {
  padding: 30px 0 30px;
  position: relative;
  overflow: hidden;
  background: #3170b6;
  @include md {
    padding: 15px 0;
  }

  .container {
    max-width: 1300px;
    margin: 40px auto;
    padding: 0 10px;

    .heading {
      h1 {
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        font-size: 4rem;
        margin: 0 auto;
        width: max-content;

        margin-bottom: 20px;

        @include sm {
          font-size: 3.5rem;
        }
      }
      p {
        font-size: 17px;
        margin: 0 0 35px;
        color: #fff;
        font-family: "Poppins";
        font-weight: 400;
        text-align: center;
      }
    }

    .accordian__grid {
      padding: 10px 30px;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 2rem;
      grid-column-gap: 1rem;
      width: 70%;
      margin: auto;

      @include lg {
        grid-template-columns: 1fr;
        width: 90%;
        padding: 10px 0;
      }

      .start {
        position: relative;
        order: 1;
        display: none;

        @include lg {
          // grid-template-columns: 1fr;
          display: none;
        }

        &::before {
          content: "";

          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .img {
          overflow: hidden;
          text-align: center;
          position: sticky;
          bottom: 50%;
          left: 0;
          transform: translateY(0%);

          background-repeat: no-repeat;
          background-size: contain;
          top: 0;
          background-position-y: 40px;
          top: 0;
          height: 600px;
          display: flex;
          align-items: flex-end;
          align-items: flex-end;

          img {
         
            height: 460px;
            object-fit: contain;
            margin-bottom: 25px;
          }

          > h1 {
            font-size: clamp(2rem, 6vw, 3rem);
            padding-bottom: 20px;
            text-align: center;
            color: #ff62ef;
            text-transform: uppercase;
            filter: drop-shadow(2px 6px 4px #00000087);
            line-height: 105%;
            -webkit-filter: drop-shadow(2px 6px 4px #00000087);
            position: relative;
            width: max-content;
            margin: 0 20px;
            font-weight: 700;

            &::before {
              content: "";
              background: linear-gradient(90deg, #fb60eb -24%, transparent);
              position: absolute;
              bottom: 30%;
              left: 0;
              width: 100%;
              height: 3px;
              z-index: 1;
              border-radius: 100px;
              -webkit-border-radius: 100px;
              -moz-border-radius: 100px;
              -ms-border-radius: 100px;
              -o-border-radius: 100px;
            }
          }

          p {
            padding: 0 21px;
            width: 90%;
            line-height: 126%;

            margin-top: -5px;
            font-size: 18px;
            margin-bottom: 20px;
            font-weight: bold;
            text-shadow: #00000080 0px 4px 4px;
          }
        }
      }

      .end {
        order: 2;

        > div {
          ul {
            margin-left: 40px;
            margin-top: 15px;
          }

          > div {
            margin-bottom: 10px;
            box-shadow: 0 5px 10px 0 #08052a1a, inset 0 2px 0 0 #fff690, inset 0 -2px 0 0 #ffa03a;
            background-blend-mode: normal, normal;
            background-image: linear-gradient(353deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.25) 0), linear-gradient(180deg, #ffcf00, #ffcf00);
            border: 2px solid #ffa03a;
            border-radius: 8px;

            svg {
              fill: black;
              width: 1em;
              height: 1em;
              display: inline-block;
              font-size: 1.5rem;
              transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              flex-shrink: 0;
              -webkit-user-select: none;
              user-select: none;
              background: no-repeat;
              border-radius: 100px;
              -webkit-border-radius: 100px;
              -moz-border-radius: 100px;
              -ms-border-radius: 100px;
              -o-border-radius: 100px;
            }

            > div {
            }

          
          }
        }
      }
    }
  }
}

.makeStyles-heading-2 {
  font-size: 18px !important;
}

.makeStyles-heading-2 {
  font-size: 0.9375rem;

  font-size: 20px !important;
  size: 1em 2.5em;
  color: #fff;
}

p.MuiTypography-root.MuiTypography-body1 {
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 18px !important;
  color: black;

  @include lg {
    font-size: 16px !important;
  }


}

.MuiTypography-body1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.MuiAccordionDetails-root {
  display: flex;
  border-top: 1px solid #a076f63d !important;
  padding: 8px 16px 16px;
}


@import "./variable.scss";
@import "./media.scss";

footer {
  position: relative;
  z-index: 100;

  > .container {

    margin: 0 auto;
    padding: 35px 15px;

    > .grid {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      text-align: center;

      .logo {
       text-align: center;
        cursor: pointer;

        img {
          max-width: 100px;
          margin-bottom: 10px;
        }
      }

      p {
        color: #eee;
        text-align: center;
opacity: .8;
      
      }
    }
  }
}

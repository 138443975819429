@import "./variable.scss";
@import "./media.scss";

header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  transition: all .5s ease-out;
  position: fixed;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#030d16a3),
    to(transparent)
  );

  .outer {
    .container {
      max-width: 1300px;
      margin: 0 auto;

      .nav__grid {
        align-items: flex-start;
        display: grid;
        grid-template-columns: 1.5fr 3fr 1.5fr;

        @include mobile {
          padding: 18px 0px;
        }
        @include lg {
          padding: 0px 10px;
        }

        .logo {
          justify-self: start;

          img {
            padding: 10px 0;
            max-width: 70px;
          }
        }

        nav {
          display: flex;
          margin: 20px 0 10px;
          justify-content: center;
          align-items: center;
          @include lg {
            margin: 0;
          }
          ul {
            display: flex;
            justify-content: center;
            list-style: none;

            li.nav__links {
              @include -lg {
                display: none;
              }
              display: flex;
              gap: 5px;
              justify-content: center;
            }

            > li {
              padding: 0 5px;
              margin-right: 5px;
              position: relative;
              transition: 0.3s all;
              font-size: 18px;
              transition: 0.1s all;
              position: relative;

              > a {
                transition: 0.2s all;
                line-height: 1.5em;
                position: relative;
                z-index: auto;
                background-position: center 0;
                cursor: pointer;
                user-select: none;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                border-radius: 4px;
                padding: 6px 12px;
                letter-spacing: 1px;
                font-weight: 500;
                text-transform: uppercase;
                font-family: "Space Grotesk", sans-serif !important;

                svg {
                  fill: #fff;
                }
                &:hover {
                  color: #ffe799;
                  letter-spacing: 4px;
                }
                @include lg {
                  font-size: clamp(3em, 11vw, 4em);
                  font-weight: 900;
                  line-height: 1;
                }
              }
            }
          }
        }

        > .nav__btns {
          justify-self: end;
          display: flex;
          align-items: center;
          gap: 0px;
          margin: 10px 0;
          a {
            margin-right: 12px;
            svg {
              height: 40px;
              width: 52px;
              border-radius: 6px;
              fill: #fff;
              transform: translateY(7px);

              @include lg {
                display: none;
              }
            }

            .btn {
              background: #4f94cf;
              transition: all 0.3s ease;
              padding: 10px 20px;
              border-radius: 0;
              font-size: 18px;
              color: #ffff;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 7px;
              font-weight: 600;
              letter-spacing: 1px;
              box-shadow: 0 5px 10px 0 #08052a33, inset 0 2px 0 0 #ff6868,
                inset 0 -2px 0 0 #b50606;
              background-blend-mode: normal, normal;
              background-image: linear-gradient(
                  353deg,
                  rgba(255, 255, 255, 0) 50%,
                  rgba(255, 255, 255, 0.25) 0
                ),
                linear-gradient(180deg, #e43535, #e43535);
              font-family: "Space Grotesk", sans-serif !important;
              margin-top: 10px;
              @include lg {
                margin-right: 50px;
              }
              &:hover {
              }
              img {
                width: 25px;
              }
            }
          }
          a.dis {
            margin-right: 0;
            svg {
              padding: 5px 8px;
            }
          }
        }
      }
    }
  }
}
.modal- {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #607d8b;
  padding: 40px 0px 20px 0px;
  border-radius: 0px;
  transition: .3s transform, opacity .3s;
  transition-timing-function: ease-out;
  z-index: 1111;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  max-width: 350px;
  width: 100%;
  height: 200px;
  display: grid;
  place-content: center;
 
box-shadow: 0 0  10px #00000080;
  >svg {
      position: fixed;
      cursor: pointer;
      top: 20px;
      right: 5%;


  }

  p {
    font-size: 25px;
    width: 80%;
    margin: auto;
    text-align: center;
    font-weight: 900;
    margin-bottom: 15px;
    line-height: 1.3;
  }

  .ico {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
          transform: scale(3);
          margin-bottom: 30px;
      }

  }

  .warn {
      svg {
          fill: #f1c40f;
      }
  }

  .info {
      svg {
          fill: #3498db;
      }
  }

  .error {
      svg {
          fill: #e74c3c;
      }
  }

  .success {
      svg {
          fill: #07bc0c;
      }
  }
}

.shadow- {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #00000057;
  height: 100%;
  z-index: 1110;
  cursor: pointer;
}
.nav__active {
  background: #210e37;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}

.auto_scroll {
  position: fixed;
  bottom: 5%;
  right: 5%;
  cursor: pointer;
  transition: 0.3s all;
  z-index: 1001;
  transform: scale(0);

  > svg {
    width: 50px;
    background: #4863db;
    height: 50px;
    place-content: center;
    border-radius: 100px;
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid #dbd9e01f;
    padding: 13px;
  }
}

@media (min-width: 992px) {
  header {
    .nav__grid {
      .nav__btns {
        > i {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 0) and (max-width: 992px) {
  header {
    .nav__grid {
      grid-template-columns: 1fr 1.5fr !important;

      .logo {
      }

      nav {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 100;
        height: 100vh;
        right: -100%;
        overflow: hidden;
        justify-content: center !important;
        background: #252b2d;
        transition: all 400ms ease 0s;

        ul {
          flex-direction: column;

          padding: 15px 10px;
          font-size: 1.4rem;

          li {
            margin-right: unset;
            margin-bottom: 15px;
          }
        }
      }

      .nav__btns {
        > div {
          cursor: pointer;
          height: 48px;
          position: absolute;
          transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
          -webkit-user-select: none;
          user-select: none;
          width: 48px;
          outline: none;
          transform: none;
          right: 2.5%;
          z-index: 1111;
          top: 15%;

          > span {
            &:nth-child(1) {
              background: #fff;
              height: 4px;
              left: 8px;
              position: absolute;
              width: 38px;
              top: 13px;
              transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
              transform: none;
            }

            &:nth-child(2) {
              background: #fff;
              height: 4px;
              left: 8px;
              position: absolute;
              width: 38px;
              top: 23px;
              transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
              opacity: 1;
            }

            &:nth-child(3) {
              background: #fff;
              height: 4px;
              left: 8px;
              position: absolute;
              width: 38px;
              top: 33px;
              transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
              transform: none;
            }
          }
        }
      }
    }
  }
}

@import url("http://fonts.cdnfonts.com/css/mister-pixel-16");


@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@100;300;400;700;900&family=Manjari:wght@100;400;700&family=Montserrat:wght@200&family=PT+Mono&family=Paytone+One&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Space+Grotesk:wght@300;400;500;600;700&family=Space+Mono:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Catamaran', sans-serif !important;
  text-decoration: none;
  color: #fff;
}
@font-face {
  font-family: "consolaz" ;
  src: url(./font/CONSOLA.TTF);
}
@font-face {
  font-family: "Rage" ;
  src: url(./font/Road_Rage/Road_Rage.otf);
}
.mar > div{
  --pause-on-hover: running;
  --pause-on-click: running;
  height: 80px;
  background: #c81717;
}
h1,
h2 {
 
  font-weight: 900;
}

::before,
::after {
  box-sizing: border-box;
}
.bottomm {

  position: relative;
}
.bottomm::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
body {
  overflow-x: hidden;
 background: #0d082c;
}


body::-webkit-scrollbar-track {
  background: #120c40;
} 

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  overflow: unset !important;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #333ea9;
  border-radius: 0;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
}
.roadmap + .react-parallax {
  background: black;
}
.roadmap + .react-parallax > img {
  opacity: 0.2;
}
html {
  scroll-behavior: smooth;
}

button .active span {
}

.makeStyles-heading-2 {
  font-size: 18px !important;
  font-weight: 400;
}

@media (max-width: 570px) {
  button {
  }
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -50px !important;
  left: 0;
  width: 100%;
}


@import "./variable.scss";
@import "./media.scss";

.roadmap {
  padding: 60px 20px 60px;
  position: relative;

  .heading {
    h1 {
      font-weight: 900;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      font-size: 4rem;
      margin: 0 auto;
      width: max-content;

      margin-bottom: 20px;

      @include sm {
        font-size: 3.5rem;
      }
    }
    p {
      font-size: 17px;
      margin: 0 0 35px;
      color: #fff;
      font-family: "Poppins";
      font-weight: 400;
      text-align: center;
    }
  }

  @include md {
    padding: 15px 0;
  }

  .container {
    max-width: 1300px;
    margin: 40px auto;
    padding: 0 10px;

    .roadmap__grid {
      padding: 20px 30px;
      display: grid;
      grid-template-columns: 1.3fr 1fr;
      grid-row-gap: 2rem;
      grid-column-gap: 3rem;
      margin-top: 50px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 30px;
        width: 1px;
        height: 96%;
        background: #3170b6;
        transform: translateX(-21px) translateY(-50%);
        border-radius: 100px;
      }
      @include lg {
        grid-template-columns: 1fr 1fr;
      }

      @include md {
        grid-template-columns: 1fr;
        padding: 10px 10px 10px 45px;
      }

      .start {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-gap: 2rem;
        position: relative;
        .img {
          position: -webkit-sticky;
          position: sticky;
          top: 10%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
          }
        }
      }

      .end {
        display: grid;
        gap: 2rem;

        .item {
          background: linear-gradient(to right, #3170b6, #3170b6);
          border-radius: 10px;
          padding: 30px 40px;
          box-shadow: none;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 18px;
            width: 25px;
            height: 25px;
            background: #3170b6;
            transform: translateX(-25px) translateY(-50%) rotate(45deg);
            border-radius: 2px;
          }
          span:nth-child(1) {
            position: absolute;
            right: 11%;
            top: 3px;
            font-size: 5rem;
            font-weight: 900;
            opacity: 0.3;
            z-index: 1;
            color: #170029;
          }

          span:nth-child(2) {
            position: absolute;
            right: 11%;
            top: 12px;
            font-size: 4rem;
            font-weight: 900;
            opacity: 1;
            color: #a076f6bd;

            z-index: 2;
          }

          h2 {
            line-height: 30px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #fff;
            font-style: normal;
            position: relative;
            font-size: 3rem;
            font-weight: 800;

            &:before {
              content: "";
              position: absolute;
              background: transparent;
              width: 15px;
              height: 15px;
              transform: rotate(360deg) translateX(-52%);
              top: -181%;
              left: -64px;
              border-radius: 2px;
            }
          }

          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            text-align: start;
            gap: 5px;
            position: relative;
            padding-bottom: 10px;
            padding-top: 15px;

            li {
              line-height: 1.2;
              letter-spacing: 0px;
              word-spacing: 0px;
              font-weight: 300;
              list-style: circle;
              margin-left: 4px;
            }
          }

          .detail {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: center;

            > h2 {
              font-weight: 500;
              margin-bottom: 5px;

              color: #f364f0;
              font-size: 2rem;
              font-weight: 800;
              filter: drop-shadow(2px 6px 4px #00000087);
            }

            > h4 {
              color: #ccc;
              font-weight: 400;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.owl-carousel .owl-stage-outer {
  position: relative;
  padding: 15px 0 !important;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

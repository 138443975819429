@mixin xldisplay() {
    @media only screen and (min-width: "1620px") {
      @content;
    }
  }
  @mixin xxdisplay() {
    @media only screen and (max-width: "1619px") {
      @content;
    }
  }
  @mixin xdisplay() {
    @media only screen and (max-width: "1419px") {
      @content;
    }
  }
  @mixin display() {
    @media only screen and (max-width: "1339px") {
      @content;
    }
  }
  @mixin xlaptop() {
    @media only screen and (max-width: "1179px") {
      @content;
    }
  }
  @mixin laptop() {
    @media only screen and (max-width: "1060px") {
      @content;
    }
  }
  @mixin xtablet() {
    @media only screen and (max-width: "767px") {
      @content;
    }
  }
  @mixin tablet() {
    @media only screen and (max-width: "639px") {
      @content;
    }
  }
  @mixin mobile {
    @media only screen and (max-width: "450px") {
      @content;
    }
  }
  @mixin smobile {
    @media only screen and (max-width: "350px") {
      @content;
    }
  }
  // another combination 
  @mixin md {
    @media only screen and (max-width: "768px") {
      @content;
    }
  }
  @mixin sm {
    @media only screen and (max-width: "500px") {
      @content;
    }
  }
  @mixin lg {
    @media only screen and (max-width: "992px") {
      @content;
    }
  }
  @mixin xl {
    @media (min-width: "1100px")  {
      @content;
    }
  }
  @mixin xxl {
    @media (min-width: "1330px") and (max-width:1600px){
      @content;
    }
  }
  @mixin sm6 {
    @media only screen and (max-width: "600px") {
      @content;
    }
  }
  @mixin -lg {
    @media  (min-width: "993px") {
      @content;
    }
  }

@import "./variable.scss";
@import "./media.scss";

.team {
  padding: 30px 0 30px;
  position: relative;
  overflow: hidden;

  @include md {
    padding: 15px 0;
  }

  > img:nth-child(1) {
    position: absolute;
    margin-top: -72%;
    width: 130vw;
    z-index: -15;
    opacity: 0.4;
    right: -58%;
    object-fit: scale-down;
  }

  > img:nth-child(2) {
    position: absolute;
    margin-top: -72%;
    width: 130vw;
    z-index: -15;
    opacity: 0.4;
    right: -58%;
    object-fit: scale-down;
  }

  .container {
    max-width: 1070px;
    margin: 40px auto;
    padding: 0 10px;

    .heading {
      h1 {
        font-size: 3.5rem;
        line-height: 100%;
        font-weight: 700;
        line-height: 118%;
        text-transform: uppercase;
        text-align: center;

        font-size: clamp(3rem, 6vw, 5rem);

        font-family: "Exo 2";
      }

      p {
        text-align: center;
        width: 50%;
        margin-bottom: 80px;
        margin: 15px auto 30px;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 500;
        line-height: 1.2;

        @include md {
          width: 90%;
          font-size: 14px;
          line-height: 1.3;
        }
      }
    }

    section.team__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem 1rem;

      @include sm6 {
        grid-template-columns: 1fr;
      }
    }

    .team__grid {
      padding: 10px;
      width: 100%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 3rem 3rem;

      .item {
        position: relative;
        margin: 0 auto;
        cursor: pointer;
        background: #3170b61f;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #4672a473;
        max-width: 313px;
        @include md {
          max-width: 300px;
        }
        &:hover {
          p {
            opacity: 1 !important;
            transform: scale(1) !important;
            top: 0 !important;
          }
          img {
            transform: scale(1.5);
          }
        }

        .img {
          position: relative;
          text-align: center;
          display: flex;
        
          transition: all 0.3s ease-in-out;
          overflow: hidden;

          img {
            width: 100%;
            position: relative;
            object-fit: cover;
            z-index: 3;
            margin: 0;
            border-radius: 0;
            height: 100%;
            transition: all 4s cubic-bezier(0, 0, 0, 1) 0s;
          }
        }

        .detail {
          padding: 20px 15px 20px;
          display: flex;
          justify-content: center;
          position: relative;
          z-index: 3;
          flex-direction: column;
          align-items: center;
          text-align: center;
          svg{
            fill:#00acee;
            display: none;
          }
          .top {
            h2 {
              font-size: 1.5rem;
              position: relative;
              text-transform: uppercase;
              letter-spacing: 0px;
              font-weight: 700;
              color: #f4ca35;
              line-height: 1.1;
            }

            h4 {
              text-align: left;
              margin-top: 5px;
              font-size: 16px;
              line-height: 1.5rem;

              color: #a2afbe;
              display: flex;
              justify-content: space-between;
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #282e6fdd;
                border: 2px solid #3676f4;
                border-radius: 100px;
                padding: 2px;
                z-index: 7;
              }
            }

            p {
              font-weight: 300;
              font-size: 18px;
              word-spacing: 1px;
              position: relative;
              line-height: 140%;
            }
          }

          .icons {
            display: flex;
            gap: 8px;
            padding: 15px 0;

            svg {
              transform: scale(1.5);
              -webkit-transform: scale(1);
              -moz-transform: scale(1.5);
              -ms-transform: scale(1.5);
              -o-transform: scale(1.5);
              width: 41px;
              height: 40px;
              padding: 0 7px;
              background: none;
              margin: 1px;
              border-radius: 6px;
              box-shadow: 0 0 5px #00000040;
              border: 1px solid #dbd9e04a;
              fill: #000000;
              background: #3a1b6a;
              background-color: #ffeb3b;
              border: 1px solid #ffffff00;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.owl-carousel .owl-stage-outer {
  position: relative;
  padding: 15px 0 !important;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

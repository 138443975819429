@import "./variable.scss";
@import "./media.scss";

.about {
  padding: 60px 0;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: linear-gradient(180deg, #140a32, #090628);
  @include md {
    padding: 30px 15px;
  }

  .container {
    margin: auto;
    max-width: 1300px;

    .about__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0rem;

      @include lg {
        display: flex;
        flex-direction: column-reverse;
      }

      .img {
        width: 100%;
        display: flex;
        margin-top: 20px;
        justify-content: center;

        figure {
          position: relative;
          margin-top: 20px;
          border-radius: 20px;
          width: -moz-fit-content;
          width: fit-content;
          margin: 0 auto;
          width: 100%;
          display: flex;

          @include lg {
            max-width: 500px;
          }

          img {
            width: 100%;
            transform: scale(1) translateY(94px);
            object-fit: contain;
            &:nth-child(1) {
              margin-left: -50px;
            }
            &:nth-child(2) {
              margin-left: -300px;
            }
           
          }
        }
      }
    }

    .details {
      padding: 20px 30px;

      @include md {
        padding: 20px 10px;
      }

      @include lg {
        order: 1;
      }

      > div {
        h2 {
          position: relative;
          margin-bottom: 24px;
          font-size: 2rem;
          color: #222220;
          font-size: 3rem;
          text-align: start;
          margin-bottom: 15px;
          font-size: clamp(3rem, 5vw, 4rem);
          line-height: 1;
          display: block;
          font-weight: 900;
          color: #ffff;
          text-transform: uppercase;
        }
      }

      > p {
        margin-bottom: 30px;

        width: 100%;
        margin-top: 20px;
        span {
          font-size: 19px;
          display: inline-block;
          line-height: 1.3;
          font-weight: 300;
          color: #fff;
          font-family: "Poppins";
          margin-bottom: 8px;
        }
      }

      button {
        font-family: "BebasNeue", cursive;
        transition: all 0.3s ease;
        padding: 12px 30px;
        font-size: 18px;
        color: #e43535;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        font-weight: 600;
        letter-spacing: 1px;
        box-shadow: 0 5px 10px 0 #08052a1a, inset 0 2px 0 0 #fff690,
          inset 0 -2px 0 0 #ffa03a;
        background-blend-mode: normal, normal;
        background-image: linear-gradient(
            353deg,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0.25) 0
          ),
          linear-gradient(180deg, #ffcf00, #ffcf00);
        border: 0;
        font-family: "Space Grotesk", sans-serif !important;
        cursor: pointer;
      }
    }
  }
}

@keyframes Utility_cardL__gYhso {
  0%,
  100% {
    transform: translateY(0) translateX(-33%);
  }

  10%,
  30%,
  50%,
  70% {
    transform: translateY(-8px) translateX(-33%);
  }

  20%,
  40%,
  60% {
    transform: translateY(8px) translateX(-33%);
  }

  80% {
    transform: translateY(6.4px) translateX(-33%);
  }

  90% {
    transform: translateY(-6.4px) translateX(-33%);
  }
}

@import "./variable.scss";
@import "./media.scss";

.collection {
  position: relative;
  z-index: 2;
  overflow: hidden;
  border-bottom: 2px solid #000;
  .line {
    position: relative;
    height: 3px;
    background: black;
  }
  > div {
    display: flex;
    align-items: center;
    position: relative;
    height: 300px;
    width: 100%;
    border-bottom: 1px solid black;
    transition: all 0.5s ease-out;
  }
  h1 {
    margin-right: 10px;
    font-family: "Evogria", sans-serif !important;
    color: #fff !important;
  }
  .img__grid {
    display: flex;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-right: 3px solid black;
    }
  }
}
